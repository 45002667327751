import { defineStore } from "pinia";
import { apiCall } from "@/api/ajax.js";
import router from "@/router";

export const useUserStore = defineStore("user", {
    state: () => {
        return {
            user: null,
            token: null,
            returnUrl: null,
            device_token: null,
            call_ended: false,
        }
    },
    getters: {
        getID: (state) => state.user
    },
    actions: {
        async signIn(email, password) {
            let UUID = null;
            if (!localStorage.getItem('uuid')) {
                // The key does not exist in local storage, so set a new value
                const { v4: uuidv4 } = require('uuid');
                console.log(uuidv4());
                localStorage.setItem('uuid', uuidv4());
                UUID = uuidv4();
            } else {
                // The key exists in local storage, so get its value
                UUID = localStorage.getItem('uuid');
            }

            let body = {
                email : email,
                password : password,
                deviceToken : this.device_token,
                deviceId : UUID,
            }
            const response = apiCall(process.env.VUE_APP_API_URL + process.env.VUE_APP_REGISTRATION_URL,body).then((res)=>{
                 if(res.status.result == "SUCCESS"){
                     this.user = res.data;
                     this.token = res.data.token;
                     localStorage.setItem('user', JSON.stringify(this.user));
                     router.push({ name: "list" });
                 }else{
                     return res;
                 }
             })
            return response;
        },
        logout() {
            this.user = null;
            this.token = null;
            localStorage.removeItem('user');
            router.push({ name: "login" });
        },
        isLoggedIn() {
            if(this.user){
                return true;
            }else{
                return false;
            }
        },
        getUserID() {
            if(this.user){
                return this.user.userId;
            }else{
                return 0;
            }
        },
        getToken() {
            if(this.user){
                return this.token;
            }else{
                return null;
            }
        },
    },
    persist: {
        storage: sessionStorage,
        paths: ['user','token','device_token','call_ended','returnUrl'],
    },
});