import { useUserStore } from "@/store/user";
import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/AppLogin.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/AppointmentList.vue')
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('@/views/AppointmentForm.vue')
  },
  {
    path: '/appointment/:id/:token',
    name: 'appointment',
    component: () => import('@/views/AppointmentScreen.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to) => {
  const userStore = useUserStore();
  console.log(to.name);
  if (userStore.user == null && to.name !== 'login') {
    return { name: 'login' }
  }else if(userStore.user != null && to.name ==='login'){
    return { name: 'list' }
  }
})

export default router
